<template>
  <div class="report">
    <b-overlay :show="loading">
      <div v-html="report"></div>
    </b-overlay>
  </div>
</template>

<script>
import ReportsApi from '@/api/reports.api'

export default {
  name: "TripReport",
  data() {
    return {
      loading: false,
      report: ''
    }
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    reset() {
      this.report = ''
    },
    show(tripId) {
      console.log(tripId)
      this.reset()
      this.$refs.modal.show()
      this.load(tripId)
    },
    load(tripId) {
      this.loading = true

      ReportsApi.tripReport({ tripId }).then(response => {
        if (response.data.report) {
          this.report = response.data.report
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
  .report {
    background: #fff;
  }
  table td {
    padding: 0;
  }
</style>